<template>
    <main class="relative">
        <error-toast
            :errorMessage="errorMessage"
            @resetErroMessage="errorMessage = null"
            class="-mt-32 z-50"
        >
            <template v-slot:error-message>
                <span class="pl-2 text-md">{{ errorMessage }}</span>
            </template>
        </error-toast>
        <header
            class="bg-primary-color-black flex items-start justify-between py-9 lg:px-64 xl:px-64 px-7 sticky top-0 "
        >
            <div
                class="flex lg:flex-row xl:flex-row flex-col lg:items-center xl:items-center items-start"
            >
                <router-link to="/">
                    <img
                        src="@/assets/images/legend-logo-white.svg"
                        alt="legend brand logo"
                        class="lg:w-full xl:w-full w-3/4 lg:mb-0 xl:mb-0 mb-3"
                    />
                </router-link>
                <span class="text-primary-color-orange lg:ml-8 xl:ml-8 ml-0"
                    >Call 0700-MY-LEGEND</span
                >
            </div>
            <div class="flex items-center">
                <div
                    class="w-10 h-10 bg-white rounded-full uppercase flex items-center justify-center font-semibold"
                >
                    nt
                </div>
                <span
                    ref="button"
                    class="text-xl text-white ml-2 cursor-pointer"
                    @click="showSignout = !showSignout"
                    >▾</span
                >
                <div
                    v-if="showSignout"
                    v-closable="{
                        exclude: ['button'],
                        handler: 'onClose',
                    }"
                    class="text-white capitalize absolute lg:right-60 xl:right-60 right-5 lg:-bottom-4 xl:-bottom-4 bottom-2 bg-primary-color-orange py-2 px-6 rounded border border-white cursor-pointer"
                >
                    sign out
                </div>
            </div>
        </header>
        <section class="lg:mx-64 xl:mx-64 mx-7">
            <div>
                <div class="my-12">
                    <h1 class="text-3xl font-semibold capitalize leading-relaxed">hello nelly</h1>
                    <p class="capitalize font-thin">welcome back!</p>
                </div>
            </div>
            <div
                class="flex lg:flex-row xl:flex-row flex-col lg:py-7 xl:py-7 py-3 lg:px-9 xl:px-9 px-4 rounded border border-border-color"
            >
                <img
                    src="@/assets/images/installer-sample-avatar.svg"
                    class="w-40 h-40 mr-10 rounded-full border border-primary-color-orange"
                />
                <div>
                    <h1 class="font-bold mb-2 text-2xl lg:mt-0 xl:mt-0 mt-7">Installer Details</h1>
                    <div class="my-1">
                        Installation address:
                        <span class="font-semibold">OAU Quarters, Maitama, Abuja.</span>
                    </div>
                    <div class="my-1">
                        Installer Name: <span class="font-semibold">Matthew</span>
                    </div>
                    <div class="my-1">
                        Installer Phone: <span class="font-semibold">08094241151</span>
                    </div>
                    <div class="my-1">
                        Supervisor Phone: <span class="font-semibold">0700 6953 4363</span>
                    </div>
                </div>
            </div>
            <div class="mt-10 mb-16">
                <h1 class="font-semibold mb-3 text-lg">Installation Progress</h1>
                <div class="border border-border-color rounded-lg text-sm w-full overflow-x-scroll">
                    <div class="capitalize">
                        <header class="flex flex-nowrap font-semibold text-center">
                            <span class="flex-none">Activity</span>
                            <span class="flex-none">Progress</span>
                            <span class="flex-none">Start Date</span>
                            <span class="flex-none">Expected Completion Date</span>
                            <span class="flex-none">Completion Date</span>
                        </header>
                        <div class="table-data flex text-center">
                            <span class="flex-none">survey</span>
                            <span class="flex-none">completed</span>
                            <span class="flex-none">30 Jan 2018 </span>
                            <span class="flex-none">13 Feb 2018 </span>
                            <span class="flex-none">31 Jan 2018</span>
                        </div>
                        <div class="table-data flex text-center">
                            <span class="flex-none">survey</span>
                            <span class="flex-none">completed</span>
                            <span class="flex-none">30 Jan 2018 </span>
                            <span class="flex-none">13 Feb 2018 </span>
                            <span class="flex-none">31 Jan 2018</span>
                        </div>
                        <div class="table-data flex text-center">
                            <span class="flex-none">survey</span>
                            <span class="flex-none">completed</span>
                            <span class="flex-none">30 Jan 2018 </span>
                            <span class="flex-none">13 Feb 2018 </span>
                            <span class="flex-none">31 Jan 2018</span>
                        </div>
                        <div class="table-data flex text-center">
                            <span class="flex-none">survey</span>
                            <span class="flex-none">completed</span>
                            <span class="flex-none">30 Jan 2018 </span>
                            <span class="flex-none">13 Feb 2018 </span>
                            <span class="flex-none">31 Jan 2018</span>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-center py-12">
                        <div class="lg:w-1/2 xl:1/2 w-11/12 text-center text-lg mb-10">
                            To begin the installation process you need to book your installer based
                            on his available schedule.
                        </div>
                        <div class="flex items-center">
                            <submit-button slot="button-text" class="z-40" @next="selectDate">
                                <span slot="button-text">Book Installer</span>
                            </submit-button>
                            <date-picker
                                v-model="selectedDate"
                                valueType="format"
                                class="-ml-36"
                            ></date-picker>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <img
            src="@/assets/images/live-chat.svg"
            alt="live chat with legend"
            class="fixed right-8 bottom-8"
        />
        <section
            v-if="selectingTime"
            class="w-screen h-screen flex items-center justify-center fixed right-0 top-0 z-40"
            style="background-color: rgba(0, 0, 0, 0.4)"
        >
            <div
                class="lg:w-2/5 xl:w-2/5 w-11/12 bg-white rounded-lg shadow-md lg:px-11 xl:px-11 px-6 py-9"
            >
                <h1 class="text-2xl font-semibold">Select available time</h1>
                <div
                    class="flex items-center justify-between mt-8 py-2 border-b border-border-color"
                >
                    <div>Tuesday, August 27</div>
                    <div
                        class="text-primary-color-orange flex items-center cursor-pointer"
                        @click="chageDate"
                    >
                        <svg
                            class="mr-1"
                            width="18"
                            height="12"
                            viewBox="0 0 18 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H18V5.25Z"
                                fill="#FF5200"
                            />
                        </svg>
                        Change date
                    </div>
                </div>
                <div @click="selectAvailableTime">
                    <div class="my-5">
                        <h1 class="mb-5 font-medium">Select available time slot</h1>
                        <div class="grid grid-cols-3 lg:gap-6 xl:gap-6 gap-3">
                            <div
                                v-for="(schedule, index) in installerSchedule.installations"
                                :key="index"
                                data-available-date
                                class="lg:text-sm xl:text-sm text-xs font-bold border border-border-color rounded-lg py-1 lg:px-1 xl:px-1 px-2 cursor-pointer text-center"
                            >
                                {{ schedule.exp_start_time }} -
                                {{ schedule.exp_comp_time }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center mt-12">
                    <button
                        class="lg:py-3 xl:py-3 py-2 w-36 bg-light-gray text-sm rounded-lg font-bold mr-6"
                        @click="cancelSelection"
                    >
                        Cancel
                    </button>
                    <button
                        class="lg:py-3 xl:py-3 py-2 w-36 bg-primary-color-orange text-white text-sm rounded-lg font-bold"
                        @click="confirmBooking"
                    >
                        Confirm Booking
                    </button>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import SubmitButton from '@/components/NextButton.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
    name: 'Dashboard',
    components: { SubmitButton, DatePicker },
    computed: {
        user() {
            return this.$store.getters.currentUser
        },
    },
    watch: {
        selectedDate() {
            if (this.selectedDate) {
                this.getInstallerSchedule(this.installer.id, this.selectedDate)
            }
        },
    },
    async mounted() {
        document.querySelector('.mx-datepicker').style.width = '120px'

        try {
            const { status, data } = await this.axios.get(
                '/get/customer/dashboard/details?customer_email=olumide@greyfusion.com.ng'
            )

            if (status === 201 && data.success) {
                this.installer = data.data.installer
                this.$store.commit('UPDATE_CURRENT_USER', data.data.installer)
                localStorage.setItem('sale_autousr_data', JSON.stringify(data.data.installer))
            }
        } catch (error) {
            this.errorMessage = error.response.data.message
        }
    },
    data() {
        return {
            selectedDate: null,
            selectedTime: null,
            selectingTime: false,
            errorMessage: null,
            showSignout: false,
            installer: null,
            installerSchedule: null,
        }
    },
    methods: {
        async getInstallerSchedule(installerId, date) {
            try {
                const { status, data } = await this.axios.get(
                    `/get/customer/installer?installer_id=${installerId}&date=${date}`
                )

                if (status >= 200 && status < 300) {
                    this.installerSchedule = data.data
                    this.selectingTime = true
                }
            } catch (error) {
                this.errorMessage = error.response.data.message
            }
        },
        disabledBeforeToday(date) {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            return date < today
        },
        onClose() {
            this.showSignout = false
        },
        chageDate() {
            this.selectingTime = false
            this.selectDate()
        },
        selectDate() {
            const input = document.querySelector('.mx-input')
            input.width = '120px'
            input.focus()
        },
        selectAvailableTime(event) {
            const targetObject = event.target.dataset
            const nodes = document.querySelectorAll('[data-available-date]')

            nodes.forEach(node => {
                node.classList.remove('active-time-state')
            })

            event.target.classList.add('active-time-state')

            if (Object.keys(targetObject).includes('availableDate')) {
                this.selectedTime = event.target.textContent
            }
        },
        cancelSelection() {
            this.selectingTime = false
            this.selectedDate = null
            this.selectedTime = null
        },
        async confirmBooking() {
            try {
                if (!this.selectedTime) {
                    this.errorMessage = 'You must select a time slot.'
                    return
                }

                const { status, data } = await this.axios.post(
                    `/book/survey?installer_id=${
                        this.installer.id
                    }&customer_id=4&exp_start_time=${this.selectedTime
                        .split('-')[0]
                        .trim()}&exp_start_date=${this.selectedDate}&type=0`
                )

                console.log(status, data)
                this.cancelSelection()
            } catch (error) {
                console.log(error)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>

<style scoped>
.active-time-state {
    background-color: #ff5200;
    color: #fff;
}

header > span,
.table-data > span {
    width: calc(100% / 5);
    border-bottom: 1px solid rgba(75, 80, 84, 0.2);
    padding: 1.25rem 0;
}

header > span {
    background-color: rgba(235, 235, 235, 0.4);
}

@media only screen and (max-width: 768px) {
    header > span,
    .table-data > span {
        width: calc(100% / 2);
    }
}
</style>
